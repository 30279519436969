import { SELECT_PLANT, STORE_FSPS, STORE_PLANTS } from '@jumbo/constants/ActionTypes';

export const setPlants = plants => {
  return dispatch => {
    dispatch({
      type: STORE_PLANTS,
      payload: plants,
    });
  };
};

export const selectPlant = plant_id => {
  return dispatch => {
    dispatch({
      type: SELECT_PLANT,
      payload: plant_id,
    });
  };
};
export const setFSPs = fsps => {
  return dispatch => {
    dispatch({
      type: STORE_FSPS,
      payload: fsps,
    });
  };
};