import axios from '../config';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import { selectPlant, setFSPs, setPlants } from 'redux/actions/Plants';

const plantAPI = {
  get: () => {
    return axios
      .get('plants/plant-details?plant_type=Hydro')
      .then(({ data }) => {
        if (data.status === 'success') {
          return data.data;
        } else {
          return [];
        }
      })
      .catch(function(error) {
        return [];
      });
  },
  addNewPlant: (plantDetails, setPlantId, setIsUpdated) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('plants/create', plantDetails)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            dispatch(fetchSuccess(data.message));
            setPlantId(data['id']);
            setIsUpdated(1);
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          console.log(error);
          if (error.response.data.status === 'error') {
            dispatch(fetchError(error.response.data.message));
          } else {
            dispatch(fetchError(error.response.data.message));
          }
        });
    };
  },
  plantList: () => {
    return dispatch => {
      axios
        .get('plants/get')
        .then(({ data }) => {
          if (data['status'] === 'success') {
            dispatch(setPlants(data['data']));
            let selectedPlant = localStorage.getItem('selectedPlant');
            if (selectedPlant) {
              dispatch(selectPlant(selectedPlant));
            } else {
              dispatch(selectPlant(data['data'][0]?.plant_id));
            }
          } else {
            // dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          // dispatch(fetchError(error.response.data.message));
        });
        axios.get('fsp/get-all')
        .then(({ data }) => {
          if (data['status'] === 'success') {
              dispatch(setFSPs(data['data']));
            }
        })
        .catch(function(error) {
          // dispatch(fetchError(error.response.data.message));
        });
    };
  },
  plantsCount: setdata => {
    return dispatch => {
      axios
        .get('plants/count')
        .then(({ data }) => {
          if (data['status'] === 'success') {
            setdata(data['data']);
          } else {
            setdata({
              total: '-',
              active: '-',
              inactive: '-',
              blocked: '-',
            });
          }
        })
        .catch(function(error) {
          setdata({
            total: '-',
            active: '-',
            inactive: '-',
            blocked: '-',
          });
        });
    };
  },
  getCurrentPlantData: (plantId, setUpdatePlant, setIsUpdated) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .get('plants/get/' + plantId)
        .then(({ data }) => {
          if (data.status === 'success') {
            dispatch(fetchSuccess(data.message));
            setUpdatePlant(data);
            setIsUpdated(0);
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.response.data.message));
        });
    };
  },
  updatePlant: (plantId, plantData, setIsUpdated) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .put('plants/update/' + plantId, plantData)
        .then(({ data }) => {
          if (data.status === 'success') {
            dispatch(fetchSuccess(data.message));
            setIsUpdated(1);
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          if (error.response.data.status === 'error') {
            dispatch(fetchError(error.response.data.message));
          } else {
            dispatch(fetchError(error.response.data.message));
          }
        });
    };
  },
  deletePlant: plantId => {
    return new Promise((resolve, reject) => {
      axios
        .delete('plants/delete/' + plantId)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            resolve(data['data']);
          } else {
            reject(data);
          }
        })
        .catch(function(error) {
          reject(error.response.data);
        });
    });
  },
  updatePlantFsp: ({ plant_id, fsp }) => {
    return axios
      .post('plants/update-fsp', { plant_id, fsp })
      .then(({ data }) => {
        return data?.status === 'success';
      })
      .catch(function(error) {
        return false;
      });
  },
};

export default plantAPI;
