import { getFormattedDate } from '@jumbo/utils/dateHelper';
import { addDays, addMonths, addYears } from 'date-fns';
import { startOfMonth, endOfMonth } from 'date-fns';
import { financial_year, month_date } from '@jumbo/utils/dateHelper';
import { isUndefined } from 'lodash';
import { localStorage_getItem } from './LocalStorage';
import moment from 'moment';

export const cardColor = ['Purple', 'Indigo', 'Blue', 'Green', 'Yellow', 'Orange', 'Red'];

export const DATE_FORMAT = 'YYYY-MM-DD';
export const UI_DATE_FORMAT = 'DD/MM/YYYY';
export const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export function formatNumber(n) {
  n = parseFloat(n);
  var ranges = [
    { divider: 1e18, suffix: 'P' },
    { divider: 1e15, suffix: 'E' },
    { divider: 1e12, suffix: 'T' },
    { divider: 1e9, suffix: 'G' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'k' },
    { divider: 1, suffix: '' },
    // { divider: 1e-3, suffix: 'm' },
    // { divider: 1e-6, suffix: 'µ' },
    // { divider: 1e-9, suffix: 'n' },
    // { divider: 1e-12, suffix: 'p' },
  ];
  for (var i = 0; i < ranges.length; i++) {
    if (Math.abs(n) >= ranges[i].divider) {
      return (n / ranges[i].divider).toFixed(2).toString() + ranges[i].suffix;
    }
  }
  return n.toFixed(2).toString();
}

export const formatCurrency = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'GBP',
});

export const formatEnergy = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
});

export function extractFilterOptionForModal(filter_option) {
  let state = '';
  let site = '';
  let dateRange = 'Last 6 months';
  let alert = '';
  filter_option.forEach(element => {
    if (element.filter_type === 'date_range') {
      dateRange = element.value;
    } else if (element.filter_type === 'sites_count') {
      site = element.value.split(',');
    } else if (element.filter_type === 'state_count') {
      state = element.value.split(',');
    } else if (element.filter_type === 'alert_count') {
      alert = element.value.toString();
    }
  });
  return [state, site, dateRange, alert];
}

export function extractFilterOption(filter_option) {
  let state = '';
  let site = '';
  let from_date = '';
  let to_date = '';
  filter_option.forEach(element => {
    if (element.filter_type === 'date_range') {
      [from_date, to_date] = extractFromAndToDate(element.value);
    } else if (element.filter_type === 'sites_count') {
      site = element.value.toString();
    } else if (element.filter_type === 'state_count') {
      state = element.value.toString();
    }
  });
  return [state, site, from_date, to_date];
}

export function extractFromAndToDate(range) {
  // console.log(' range ->', range);
  let obj = {
    startDate: new Date(),
    endDate: new Date(),
  };
  if (range === 'Today') {
  } else if (range === 'Last 7 days') {
    obj.startDate = addDays(obj.endDate, -6);
  } else if (range === 'Last 14 days') {
    obj.startDate = addDays(obj.endDate, -13);
  } else if (range === 'Last month') {
    obj.startDate = addDays(obj.endDate, -29);
  } else if (range === 'Last 6 months') {
    obj.startDate = addMonths(obj.endDate, -6);
  } else if (range === 'Last 14 days') {
    obj.startDate = addDays(obj.endDate, -13);
  } else if (range === 'Last 12 months') {
    obj.startDate = addYears(obj.endDate, -1);
  } else if (range === 'Year to Date') {
    obj.startDate = financial_year(new Date());
  } else if (range === 'Month to Date') {
    obj.startDate = month_date(new Date());
  } else {
    if (!isUndefined(range)) {
      let arr = range.split(' to ');
      obj.startDate = new Date(arr[0]);
      obj.endDate = new Date(arr[1]);
    }
  }
  //console.log(' obj.startDate ->', getFormattedDate(obj.startDate), ' & obj.endDate ->', getFormattedDate(obj.endDate));
  return [getFormattedDate(obj.startDate), getFormattedDate(obj.endDate)];
}

export const getDateRange = (from_date, to_date, date) => {
  let start_date = startOfMonth(date);
  let end_date = endOfMonth(date);
  from_date = new Date(from_date);
  to_date = new Date(to_date);
  return [
    getFormattedDate(from_date > start_date ? from_date : start_date),
    getFormattedDate(to_date < end_date ? to_date : end_date),
  ];
};

export function checkPageLevelAccess(accessList, userType, tabLabel) {
  let access = {
    view: false,
    edit: false,
  };
  if (userType !== null && userType == 1) {
    access.edit = true;
    access.view = true;
  } else if (
    typeof accessList === 'object' &&
    typeof accessList.edit_page_level_access !== 'undefined' &&
    accessList.edit_page_level_access.length > 0
  ) {
    if (accessList.edit_page_level_access.includes(`${tabLabel}`)) {
      access.edit = true;
      access.view = true;
    }
  } else if (
    typeof accessList === 'object' &&
    typeof accessList.view_page_level_access !== 'undefined' &&
    accessList.view_page_level_access.length > 0
  ) {
    if (accessList.view_page_level_access.includes(`${tabLabel}`)) {
      access.edit = false;
      access.view = true;
    }
  }
  return access;
}

export const urlPatternValidation = URL => {
  const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
  return regex.test(URL);
};

export const sentanceCase = str => {
  str = str.toLowerCase().split(' ');
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
};

//['primary','primary_hover','secondary','secondary_hover','mis'];
export const theme_obj = {
  Default: {
    color: ['#8a8787', '#EAEBEB', '#056C45', '#018786', '#B2DF8A'],
  },
  Light: {
    // color: ['#FFD166', '#FFE7AF', '#c58882', '#df928e', '#1d201f'],
    // color: ['#ccd5ae', '#e9edc9', '#faedcd', '#fefae0', '#d4a373'],
    // color: ['#089DD9', '#C3F7FE', '#00a896', '#02c39a', '#f0f3bd'],
    color: ['#1565c0', '#003c8f', '#18227c', '#00004f', '#fff'],
  },
  Dark: {
    color: ['#000000', '#00000033', '#4f5b66', '#c0c5ce', '#65737e'],
    // color: ['#20123c', '#271e4b', '#241056', '#3b2763', '#55417b'],
  },
};
// {
//   name: 'Modern',
//   color: ['#1e1e1e', '#3858e9', '#33f078'],
// },
// {
//   name: 'Blue',
//   color: ['#096484', '#4796b3', '#52accc', '#74B6CE'],
// },
// {
//   name: 'Coffee',
//   color: ['#46403c', '#59524c', '#c7a589', '#9ea476'],
// },
// {
//   name: 'Ectoplasm',
//   color: ['#413256', '#523f6d', '#a3b745', '#d46f15'],
// },
// {
//   name: 'Midnight',
//   color: ['#25282b', '#363b3f', '#69a8bb', '#e14d43'],
// },
// {
//   name: 'Ocean',
//   color: ['#627c83', '#738e96', '#9ebaa0', '#aa9d88'],
// },
// {
//   name: 'Sunrise',
//   color: ['#b43c38', '#cf4944', '#dd823b', '#ccaf0b'],
// },
// ];

export const capitalizeFirst = text => {
  let textConvert = '';
  if (text !== '') {
    textConvert = text.slice(0, 1).toUpperCase() + text.slice(1, text.length);
  }
  return textConvert;
};

export const a11yProps = index => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

export const currentBlockNo = () => {
  let date = moment();
  let hr = +date.format('HH');
  let min = +date.format('mm');
  return hr * 4 + parseInt(min / 15);
};

export const timeBlocks = () => {
  let startdate = moment().startOf('day');
  let enddate = moment().endOf('day');
  let tb = [];
  while (startdate.isBefore(enddate)) {
    tb.push(startdate.format('HH:mm') + '-' + startdate.add(15, 'minutes').format('HH:mm'));
  }
  return tb;
};

export const extraxtActual = (actual, forecast) => {
  if (!actual || actual.length === 0) return {};
  let i = actual.length - 1;
  while (actual[i][1] === null) {
    i--;
  }
  let time = actual[i][0];
  let currActual = actual[i][1];
  let forecastBlock = forecast && forecast.find(x => x[0] === time);
  let currForecast = (forecastBlock && forecastBlock[1]) || null;
  let timeblock =
    moment(time, 'x')
      .utc()
      .format('HH:mm') +
    '-' +
    moment(time, 'x')
      .add(15, 'minutes')
      .utc()
      .format('HH:mm');
  return { timeblock, actual: currActual, forecast: currForecast };
};

export const getRevision = (type, plantType, plantId) => {
  let blockNo = currentBlockNo();
  let newR = 0;
  if (type === 'intraday') {
    if (plantType === 'solar') {
      newR = Math.ceil(blockNo / 2) - 8;
      newR = newR >0 ?newR:1;
    } else {
      newR = Math.ceil(blockNo / 2) + 1;
    }
  } else {
    newR = blockNo < 20 ? 1 : blockNo < 34 ? 2 : blockNo < 91 ? 3 : blockNo < 93 ? 4:5 ;
    if([206,207,55,53].includes(plantId)){
      newR = blockNo >20  && blockNo < 37 ? 2 : newR;
    }
  }
  return newR;
};
